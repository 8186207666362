// ModernMT
// Static pages scripts

import Headroom from 'headroom.js'
import Pristine from 'pristinejs'
import md5 from 'crypto-js/md5'
import { getSiblings, serialize, getValue, resetForm } from './utils/utils.js'
import {
  getUserTokenOnLocalstorage,
  getUserIsLoggedIn,
} from '../../services/localstorage.js'
import * as moment from 'moment'

class CustomRange {
  constructor(id, callback) {
    this.el = document.querySelector(id)
    this.format = null
    this.label = null
    this.srValue = null
    this.fill = null
    this.callback = callback

    if (this.el) {
      this.buildSlider()
      this.el.addEventListener('input', this.changeValue.bind(this))
    }
  }
  buildSlider() {
    // get options
    this.format = this.el.dataset.format || 'decimal'

    // get the label
    this.label = this.el.parentElement.querySelector('label')

    // create a div to contain the <input> and its <label>
    let rangeWrap = document.createElement('div')
    rangeWrap.className = 'range'
    this.el.parentElement.insertBefore(rangeWrap, this.el)
    rangeWrap.appendChild(this.label)

    // create another div to contain the <input> and fill
    let rangeInput = document.createElement('span')
    rangeInput.className = 'range__input'
    rangeWrap.appendChild(rangeInput)

    // range fill, place the <input> and fill inside container <span>
    let rangeFill = document.createElement('span')
    rangeFill.className = 'range__input-fill'
    rangeInput.appendChild(this.el)
    rangeInput.appendChild(rangeFill)

    // create the counter
    let counter = this.label.querySelector('.value')

    this.fill = rangeFill
    this.srValue = counter

    this.changeValue()
  }
  changeValue() {
    // keep the value within range
    if (+this.el.value > this.el.max) this.el.value = this.el.max
    else if (+this.el.value < this.el.min) this.el.value = this.el.min

    // update the screen reader value
    if (this.srValue)
      this.srValue.textContent = new Intl.NumberFormat('en-US', {
        style: this.format,
        minimumFractionDigits: this.format === 'percent' ? 1 : 0,
      }).format(this.el.value)

    // width of fill
    if (this.fill) {
      this.fill.style.width = `${(this.el.value / this.el.max) * 100}%`
    }

    // Trigger additional callback
    this.callback()
  }
}

class Static {
  constructor() {
    //this.value = 1
    this.header()
    this.tabs()
    this.formValidation()
    this.hitlEstimate()
  }
  header() {
    const header = document.querySelector('body > header')
    const hamburger = document.querySelector('#input-hamburger')
    let headertopdelay
    if (hamburger && header) {
      //hamburger button
      hamburger.addEventListener(
        'change',
        function () {
          if (this.checked) header.classList.add('header--menu-open')
          else header.classList.remove('header--menu-open')
        },
        false
      )
      //fixed header on scroll-up
      const headroom = new Headroom(header, {
        offset: 20,
        tolerance: { up: 5, down: 0 },
        classes: {
          initial: 'header',
          pinned: 'header--pinned',
          unpinned: 'header--unpinned',
          top: 'header--top',
          notTop: 'header--not-top',
          bottom: 'header--bottom',
          notBottom: 'header--not-bottom',
        },
        onTop: function () {
          clearTimeout(headertopdelay)
          header.classList.add('header--top-delay')
        },
        onNotTop: function () {
          headertopdelay = setTimeout(function () {
            header.classList.remove('header--top-delay')
          }, 500)
        },
      })
      headroom.init()
      //logged check
      if (getUserTokenOnLocalstorage() && getUserIsLoggedIn()) {
        header.classList.add('header--logged')
      }
      //fix back button
      setTimeout(function () {
        if (window.pageYOffset) {
          window.scrollTo(0, window.pageYOffset + 1)
        }
      }, 100)
    }
  }
  tabs() {
    const tabLinks = document.getElementsByClassName('tab__link')
    Array.prototype.forEach.call(tabLinks, function (tabLink) {
      tabLink.addEventListener(
        'click',
        function (e) {
          e.preventDefault()
          //remove/add tablink class
          const tabLinkSiblings = getSiblings(tabLink)
          tabLink.classList.add('tab__link--active')
          Array.prototype.forEach.call(
            tabLinkSiblings,
            function (tabLinkSibling) {
              tabLinkSibling.classList.remove('tab__link--active')
            }
          )
          //remove/add tabpanel class
          const tabLinkID = tabLink.getAttribute('data-tab')
          const tabPanel = document.getElementById(tabLinkID)
          const tabPanelSiblings = getSiblings(tabPanel)
          if (tabPanel) {
            tabPanel.classList.add('tab__panel--active')
            Array.prototype.forEach.call(
              tabPanelSiblings,
              function (tabPanelSibling) {
                tabPanelSibling.classList.remove('tab__panel--active')
              }
            )
          }
          //append hash
          window.location.hash = tabLinkID.replace('-tab', '')
        },
        false
      )
    })
    if (window.location.hash) {
      const hash = window.location.hash + '-tab'
      const tabPanel = document.querySelector(hash)
      const tabLinkSelector = '[data-tab=' + hash.replace('#', '') + ']'
      const tabLink = document.querySelector(tabLinkSelector)
      if (tabPanel && tabLink) {
        const tabPanelSiblings = getSiblings(tabPanel)
        const tabLinkSiblings = getSiblings(tabLink)
        tabPanel.classList.add('tab__panel--active')
        tabLink.classList.add('tab__link--active')
        Array.prototype.forEach.call(
          tabPanelSiblings,
          function (tabPanelSibling) {
            tabPanelSibling.classList.remove('tab__panel--active')
          }
        )
        Array.prototype.forEach.call(
          tabLinkSiblings,
          function (tabLinkSibling) {
            tabLinkSibling.classList.remove('tab__link--active')
          }
        )
      }
    }
  }
  formValidation() {
    const root = this
    let contactForm = document.getElementById('contact-form')
    const messageBox = document.querySelector('#contact-form .message-box')
    const termsCheck = document.getElementsByName('terms_aaoi09')
    if (contactForm && messageBox && termsCheck) {
      const validationConfig = {
        classTo: 'input-container__item',
        errorClass: 'has-error',
        successClass: 'has-success',
        errorTextParent: 'input-container__item',
        errorTextTag: 'small',
        errorTextClass: '',
      }
      const pristine = new Pristine(contactForm, validationConfig)
      contactForm.addEventListener('submit', function (e: any) {
        e.preventDefault()
        messageBox.className = 'message-box'
        messageBox.innerHTML = ''
        pristine.validate()
        if (
          pristine.getErrors().length < 2 &&
          !pristine.getErrors(termsCheck).length
        ) {
          root.formSubmit()
        }
      })
    }
  }
  formSubmit() {
    window.dataLayer.push({ event: 'form_sent' })
    const submitButton = document.querySelector('#contact-form [type=submit]')
    const messageBox = document.querySelector('#contact-form .message-box')
    const name = document.getElementsByName('name_egas76')
    const email = document.getElementsByName('email_vbcv62')
    const emailVerify = document.getElementsByName('emailVerify_ioee11')
    const message = document.getElementsByName('message_bvnb31')
    const form = document.getElementById('contact-form')
    const clientKey = process.env.CLIENT_PRIVATE_KEY
    let url = process.env.WEB_API_URL
    url = url ? url + '/contact' : ''
    if (
      submitButton &&
      messageBox &&
      name &&
      email &&
      emailVerify &&
      message &&
      form &&
      clientKey &&
      url
    ) {
      const submitSuccess = messageBox.getAttribute('data-success')
      const submitError = messageBox.getAttribute('data-error')
      const request = {
        name: getValue(name),
        email: getValue(email),
        emailVerify: getValue(emailVerify),
        verify: md5(clientKey + '#' + getValue(emailVerify)).toString(),
        message: getValue(message),
      }
      let httpRequest = new XMLHttpRequest()
      submitButton.classList.add('is-loading')
      httpRequest.onreadystatechange = function () {
        setTimeout(function () {
          submitButton.classList.remove('is-loading')
        }, 500)
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
          if (httpRequest.status === 200) {
            messageBox.classList.add('message-box--success')
            messageBox.innerHTML = submitSuccess ? submitSuccess : ''
            resetForm(form)
          } else {
            messageBox.classList.add('message-box--error')
            messageBox.innerHTML = submitError ? submitError : ''
          }
        }
      }
      resetForm(form)
      httpRequest.open('POST', url)
      httpRequest.setRequestHeader(
        'Content-Type',
        'application/x-www-form-urlencoded'
      )
      httpRequest.send(serialize(request))
    }
  }
  loaded() {
    //$FlowIgnore
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': moment.utc().valueOf(), event: 'gtm.js' })
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      // $FlowIgnore
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      // $FlowIgnore
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', process.env.GTM_KEY)
  }
  hitlEstimate() {
    let  human
    const updateResult = function () {
      if (human && human.el) {
        let result = document.querySelector('.hitl-estimate__result .value')
        const resultValue = Math.max(human.el.value * 0.14 * 1_000_000 / 5 ,15)
        result.textContent = `${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(resultValue)} / 1M chars`
      }
    }

    window.addEventListener('DOMContentLoaded', () => {
      human = new CustomRange('#human', updateResult)

      updateResult()
    })
  }
}

const page = new Static()
window.addEventListener('load', () => page.loaded())
