// @flow
export function setUserTokenOnLocalstorage(token: string): void {
  token.length > 0 && localStorage.setItem('token', JSON.stringify(token))
}

export function setLicenseKeyOnLocalstorage(licenseKey: string): void {
  licenseKey.length > 0 && localStorage.setItem('key', JSON.stringify(licenseKey))
}

export function getUserTokenOnLocalstorage(): string {
  const userToken = localStorage.getItem('token')
  return userToken ? JSON.parse(userToken) : ''
}

export function getLicenseKeyOnLocalstorage(): string {
  const key = localStorage.getItem('key')
  return key ? JSON.parse(key) : ''
}

export function setUserIsLoggedIn(): void {
  localStorage.setItem('user-is-logged', 'true')
}

export function getUserIsLoggedIn(): boolean {
  return !!localStorage.getItem('user-is-logged')
}

export function removeUserIsLoggedIn(): void {
  localStorage.removeItem('user-is-logged')
}

export function getUserLoggedOut(): void {
  removeUserIsLoggedIn()
  removeActiveMemoriesOnLocalstoriage()
  localStorage.removeItem('token')
  localStorage.removeItem('key')
}

export function getActiveMemoriesOnLocalstorage() {
  const memories = localStorage.getItem('active-memories') || ''
  return memories.length > 0 ? JSON.parse(memories) : []
}

export function removeActiveMemoriesOnLocalstoriage() {
  localStorage.removeItem('active-memories')
}

export function setActiveMemoriesOnLocalstorage(memories: Array<string>) {
  removeActiveMemoriesOnLocalstoriage()
  memories.length > 0 && localStorage.setItem('active-memories', JSON.stringify(memories))
}

export const getTranslatorSource = () => localStorage.getItem('translator-source')
export const getTranslatorTarget = () => localStorage.getItem('translator-target')

export const setTranslatorSource = (lang: string) => localStorage.setItem('translator-source', lang)
export const setTranslatorTarget = (lang: string) => localStorage.setItem('translator-target', lang)
