export const getSiblings = (elem: any) => {
  var siblings = []
  var sibling = elem.parentNode.firstChild
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling)
    }
    sibling = sibling.nextSibling
  }
  return siblings
}

export const serialize = (object: any) => {
  var parameters = []
  for (var property in object) {
    if (Object.hasOwnProperty.call(object, property)) {
      parameters.push(encodeURI(property + '=' + object[property]))
    }
  }
  return parameters.join('&')
}

export const getValue = (input: any) => {
  if (input && (input[0] instanceof HTMLInputElement || input[0] instanceof HTMLTextAreaElement)) {
    return input[0].value
  }
  return ''
}

export const resetForm = (form: any) => {
  if (form instanceof HTMLFormElement) {
    return form.reset()
  }
  return
}
